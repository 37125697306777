<template>
  <div v-show="items.length > 0" ref="dropdown" class="etn-dropdown" @click.prevent="onClick">
    <a v-bind="$attrs" href="#" class="btn btn-text-secondary">
      <i class="fa fa-ellipsis" />
    </a>
    <div class="etn-dropdown-wrapper" :class="{ show }">
      <div ref="dropdown-menu" class="etn-dropdown-menu rounded-md">
        <ul class="py-2">
          <li v-for="item in items" :key="item.name" :class="{ danger: item.danger }">
            <a href="#" @click.prevent="onClickItem(item)"
              ><i v-if="item.icon" class="fad font-w500 fa-fw mr-3" :class="item.icon" />{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    onClick() {
      if (!this.show) {
        document.addEventListener('click', this.onClickOutside);
      }

      this.show = !this.show;
    },
    onClickItem(item) {
      item.onClick();
      this.onClose();
    },
    onClickOutside(el) {
      if (this.show && !this.$refs.dropdown?.contains(el.target)) {
        this.onClose();
      }
    },
    onClose() {
      this.show = false;
      document.removeEventListener('click', this.onClickOutside);
    }
  }
};
</script>
<style scoped>
.etn-dropdown-wrapper {
  position: relative;
  display: inline-block;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  transform-origin: top left;
  transform: scale(0.95);

  transition: 0.15s cubic-bezier(0, 0, 0.2, 1);
}

.etn-dropdown-wrapper.show {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.etn-dropdown-menu {
  position: absolute;
  top: 10px;
  right: 0px;

  background-color: #fff;
  min-width: 160px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}

.etn-dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.etn-dropdown-menu ul li:hover {
  background-color: rgba(101, 193, 152, 0.1);
}

.etn-dropdown-menu ul li a {
  display: block;
  padding: 6px 16px;
}

.etn-dropdown-menu ul li:hover a {
  color: #65c198 !important;
}

.etn-dropdown-menu ul li.danger:hover {
  background-color: rgba(224, 79, 26, 0.1);
}

.etn-dropdown-menu ul li.danger:hover a {
  color: #e04f1a !important;
}
</style>
